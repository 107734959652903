<template>
	<div>
	    <span><a href="#" style="color:#999;" @click="termpush('TK001')">隐私政策</a></span>
	    <el-divider direction="vertical"></el-divider>
	    <span><a href="#" style="color:#999;" @click="termpush('TK002')">法律声明</a></span>
	    <el-divider direction="vertical"></el-divider>
	    <span> © 2023-2023 杭州空盈科技有限公司 版权所有 </span>
		<el-divider direction="vertical"></el-divider>
		<span><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="color:#999;">浙ICP备2023016071号-1</a></span>
	  </div>
</template>

<script>
	import { reactive, ref, toRefs, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue' 
	import { useRouter } from 'vue-router' 
	  
	export default {
	  name: 'breadcrumb',
	  props:['params'],
	  components: {   
	  },
	  setup(props) {   
	    const router = useRouter()
	    const react = reactive({
			propsdata:props.params 
	    })  
	    // onMounted(() => {  
		   // // console.log('action', react.propsdata)  
	    // })  
		const termpush = (number) => {
			router.push({ path:'/TermView', query: {number:number} })
		} 
	    return {
	      ...toRefs(react),
		  termpush
	    }
	  }
	}
</script>

<style> 
</style>